import {
  RESET_ALL_MSG,
  RESET_ERROR_MSG,
  RESET_SUCCESS_MSG,
  SET_ERROR_MSG,
  SET_SUCCESS_MSG
} from "../../config/actions";

const notification = (
  state = {
    success: null,
    error: null
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SUCCESS_MSG:
      return Object.assign({}, state, { success: payload, error: null });

    case RESET_SUCCESS_MSG:
      return Object.assign({}, state, { success: null });

    case SET_ERROR_MSG:
      return Object.assign({}, state, {
        error: action.payload
      });

    case RESET_ERROR_MSG:
      return Object.assign({}, state, {
        error: null
      });

    case RESET_ALL_MSG:
      return Object.assign({}, state, { success: null, error: null });

    default:
      return state;
  }
};

export default notification;
