import { LOAD_AI_CONFIG, LOAD_AI_CONFIGS, CLEAR_AI_CONFIG } from '../../config/actions';

const aiConfig = (state = { configList: null, config: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_AI_CONFIGS:
      return Object.assign({}, state, { configList: payload.data });
    case LOAD_AI_CONFIG:
      return Object.assign({}, state, { config: payload.data });
    case CLEAR_AI_CONFIG:
      return Object.assign({}, state, { config: null });

    default:
      return state;
  }
};

export default aiConfig;