import {
  ADD_AIASSISTANCE_QUESTION,
  UPDATE_AIASSISTANCE_ANSWER,
  RESET_AIASSISTANCE_QNA,
  INITIATE_AIASSISTANCE,
} from '../../config/actions';

const aiAssistance = (
  state = { qna: [], isLoading: false, initAssistance: false },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_AIASSISTANCE_QUESTION:
      const currentQna = { ...payload.data };
      return Object.assign({}, state, {
        qna: [...state.qna, currentQna],
        isLoading: state.initAssistance ? false : true,
        initAssistance: false,
      });
    case UPDATE_AIASSISTANCE_ANSWER:
      return Object.assign({}, state, {
        qna: [...state.qna.slice(0, -1), payload.data],
        isLoading: false,
        initAssistance: false,
      });
    case INITIATE_AIASSISTANCE:
      return Object.assign({}, state, { initAssistance: true });
    case RESET_AIASSISTANCE_QNA:
      return Object.assign({}, state, {
        qna: [],
        isLoading: false,
        initAssistance: false,
      });
    default:
      return state;
  }
};

export default aiAssistance;
