import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AppRoute from "./router";
import AuthRoute from "./auth";

const Login = lazy(() => import("../components/Login"));
const ForgotPassword = lazy(() => import("../components/ForgotPassword"));
const ResetPassword = lazy(() => import("../components/ResetPassword"));
const Signup = lazy(() => import("../components/Signup"));
const Launcher = lazy(() => import("../components/Launcher"));
// const Home = lazy(() => import("../components/Home"));
const Sites = lazy(() => import("../components/Sites"));
const Users = lazy(() => import("../components/Users"));
const Account = lazy(() => import("../components/Account"));
const EditAccount = lazy(() => import("../components/EditAccount"));
const EditAccountPassword = lazy(() => import("../components/EditPassword"));

// const Support = lazy(() => import("../components/Support"));
// const Message = lazy(() => import("../components/Message"));
const Site = lazy(() => import("../components/Site"));
const Notifications = lazy(() => import("../components/Notifications"));
const ErrorPage = lazy(() => import("../components/ErrorPage"));
const UserAdmin = lazy(() => import("../components/UserAdmin"));
const SiteAdmin = lazy(() => import("../components/SiteAdmin"));
const WcagList = lazy(() => import("../components/WcagList"));
const AIConfig = lazy(() => import("../components/AIConfiguration"));
const TemplatesAdmin = lazy(() => import("../components/EmailTemplatesAdmin"));
const Logout = lazy(() => import("../components/Logout"));

// const MediaQueryTest = lazy(() => import("../components/MediaQueryTest"));
const EmailTemplate = lazy(() => import("../components/EmailTemplate"));

const AppRoutes = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {/* <AuthRoute title="Dashboard" path="/" exact component={Home} /> */}
        <AppRoute title="Login" path="/signin" exact component={Login} />
        <AppRoute
          title="Forgot Password"
          path="/reset"
          exact
          component={ForgotPassword}
        />
        <AppRoute
          title="Reset Password"
          path="/update/:email/:code"
          exact
          component={ResetPassword}
        />
        <AppRoute title="Sign Up" path="/signup" exact component={Signup} />
        <AppRoute title="Join" path="/join/:id" exact component={Signup} />
        <AppRoute
          title="Verify Email"
          path="/verify/:email/:code"
          exact
          component={Launcher}
        />
        <AuthRoute title="My Sites" path="/" exact component={Sites} />
        <AuthRoute title="Site" path="/sites/:id/:type" exact component={Site} />
        <AuthRoute title="Site" path="/sites/:id/manual/:reportId" exact component={Site} />
        
        <AuthRoute
          title="Site Error"
          path="/sites/:id/:errorName/:level/:nodeType"
          exact
          component={ErrorPage}
        />

        <AuthRoute
          title="Manage Users"
          path="/users"
          exact
          component={Users}
          role="Administrator"
        />

        <AuthRoute
          title="My Account"
          path="/account"
          exact
          component={Account}
        />
        <AuthRoute
          title="Edit My Account"
          path="/account/edit"
          exact
          component={EditAccount}
        />
        <AuthRoute
          title="Change Password"
          path="/account/password"
          exact
          component={EditAccountPassword}
        />
        {/* <AuthRoute title="Support" path="/support" exact component={Support} /> */}
        {/* <AuthRoute title="Message" path="/message" exact component={Message} /> */}
        {/* <AuthRoute title="Administration" path="/admin" exact component={Administration} /> */}
        <AuthRoute
          title="User Administration"
          path="/admin/users"
          exact
          component={UserAdmin}
          role="staff"
        />
        <AuthRoute
          title="Site Administration"
          path="/admin/sites"
          exact
          component={SiteAdmin}
          role="staff"
        />
        <AuthRoute
          title="WcagList"
          path="/admin/wcag"
          exact
          component={WcagList}
          role="staff"
        />
        <AuthRoute
          title="AI Service Configuration"
          path="/admin/ai-config"
          exact
          component={AIConfig}
          role="staff"
        />
        <AuthRoute
          title="Email Templates"
          path="/admin/templates"
          exact
          component={TemplatesAdmin}
          role="staff"
        />
        <AuthRoute title="Notifications" path="/notifications" exact component={Notifications} />
        <AuthRoute title="Logout" path="/logout" exact component={Logout} />
        <Route path="/email" exact component={EmailTemplate} />
        <AppRoute
          title="Page Not Found"
          component={() => <div>404 - Page not found</div>}
        />
      </Switch>
    </Suspense>
  </Router>
);

export default AppRoutes;
