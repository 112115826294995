import {
  LOAD_ADMIN_SITES,
  SET_FILTERS_ADMIN_SITES,
  RESET_ADMIN_SITES,
  TOGGLE_SITE_AIASSIST,
} from '../../config/actions';

const adminSite = (
  state = {
    searchTerm: '',
    limit: 10,
    offset: 0,
    sortOrder: 'asc',
    data: null,
    pageInfo: { count: 0 },
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ADMIN_SITES:
      return Object.assign({}, state, { ...payload });

    case SET_FILTERS_ADMIN_SITES:
      return Object.assign({}, state, {
        limit: payload.limit,
        offset: payload.offset,
      });
    case RESET_ADMIN_SITES:
      return Object.assign({}, state, {
        searchTerm: '',
        limit: 10,
        offset: 0,
        sortOrder: 'asc',
        data: null,
        pageInfo: { count: 0 },
      });

    case TOGGLE_SITE_AIASSIST:
      const { siteId, enabled } = payload;
      const updatedData = [...(state.data || [])].map((item) => {
        if (item._id === siteId) {
          item.enableAIAssist = enabled;
        }

        return item;
      });
      return Object.assign({}, state, {
        data: updatedData,
      });
    default:
      return state;
  }
};

export default adminSite;
