export const SET_SUCCESS_MSG = 'SET_SUCCESS_MSG';
export const RESET_SUCCESS_MSG = 'RESET_SUCCESS_MSG';
export const SET_ERROR_MSG = 'SET_ERROR_MSG';
export const RESET_ERROR_MSG = 'RESET_ERROR_MSG';
export const RESET_ALL_MSG = 'RESET_ALL_MSG';

export const LOAD_OWN_SITES_LIST = 'LOAD_OWN_SITES_LIST';
export const LOAD_DASHBOARD_SITES = 'LOAD_DASHBOARD_SITES';
export const LOAD_DASHBOARD_SITES_STATS = 'LOAD_DASHBOARD_SITES_STATS';
export const RESET_DASHBOARD_SITES = 'RESET_DASHBOARD_SITES';
export const RESET_DASHBOARD_SITES_STATS = 'RESET_DASHBOARD_SITES_STATS';

export const LOAD_SITES = 'LOAD_SITES';
export const ADD_SITE = 'ADD_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';

export const LOAD_SITE = 'LOAD_SITE';
export const LOAD_SITE_STATS_VIOLATION = 'LOAD_SITE_STATS_VIOLATION';
export const LOAD_SITE_ERRORS_VIOLATION = 'LOAD_SITE_ERRORS_VIOLATION';
export const RESET_SITE = 'RESET_SITE';

export const LOAD_SITE_ERROR = 'LOAD_SITE_ERROR';
export const RESET_SITE_ERROR = 'RESET_SITE_ERROR';
export const LOAD_SITE_ERROR_ELEMENTS = 'LOAD_SITE_ERROR_ELEMENTS';
export const APPEND_SITE_ERROR_ELEMENTS = 'APPEND_SITE_ERROR_ELEMENTS';
export const SET_SITE_ERROR_LOADER = 'SET_SITE_ERROR_LOADER';

export const LOAD_USERS = 'LOAD_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const LOAD_CURRENT_USER_DOMAINS = 'LOAD_CURRENT_USER_DOMAINS';
export const LOAD_CURRENT_USER_ROLES = 'LOAD_CURRENT_USER_ROLES';

export const LOAD_ACCOUNT_PROFILE = 'LOAD_ACCOUNT_PROFILE';
export const LOAD_ACCOUNT_PREFERENCES = 'LOAD_ACCOUNT_PREFERENCES';

export const LOAD_ADMIN_SITES = 'LOAD_ADMIN_SITES';
export const SET_FILTERS_ADMIN_SITES = 'SET_FILTERS_ADMIN_SITES';
export const SET_SEARCHTERM_ADMIN_SITES = 'SET_SEARCHTERM_ADMIN_SITES';
export const RESET_ADMIN_SITES = 'RESET_ADMIN_SITES';
export const TOGGLE_SITE_AIASSIST = 'TOGGLE_SITE_AIASSIST';

export const LOAD_ADMIN_TEMPLATES = 'LOAD_ADMIN_TEMPLATES';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const RESET_SELECTED_TEMPLATE = 'RESET_ADMIN_TEMPLATE';

export const LOAD_ADMIN_USERS = 'LOAD_ADMIN_USERS';
export const SET_FILTERS_ADMIN_USERS = 'SET_FILTERS_ADMIN_USERS';
export const SET_SEARCHTERM_ADMIN_USERS = 'SET_SEARCHTERM_ADMIN_USERS';
export const GET_ADMIN_USER_ROLE = 'GET_ADMIN_USER_ROLE';

export const LOAD_MANUAL_REPORT = 'LOAD_MANUAL_REPORT';
export const LOAD_MANUAL_REPORTS = 'LOAD_MANUAL_REPORTS';
export const LOAD_MANUAL_REPORTS_CHART = 'LOAD_MANUAL_REPORTS';
export const RESET_MANUAL_REPORT = 'DELETE_MANUAL_REPORT';
export const RESET_MANUAL_REPORT_REPORT = 'RESET_MANUAL_REPORT_REPORT';
export const SET_CONTACT_STATUS = 'SET_CONTACT_STATUS';

export const LOAD_MANUAL_REPORT_ERRORS = 'LOAD_MANUAL_REPORT_ERRORS';
export const LOAD_MANUAL_REPORT_DETAILS = 'LOAD_MANUAL_REPORT_DETAILS';
export const DELETE_MANUAL_REPORT_ERROR = 'DELETE_MANUAL_REPORT_ERROR';
export const RESET_MANUAL_REPORT_ERROR = 'RESET_MANUAL_REPORT_ERROR';
export const LOAD_MANUAL_REPORT_ERROR = 'LOAD_MANUAL_REPORT_ERROR';
export const UPDATE_MANUAL_REPORT_ERROR_FORM =
  'UPDATE_MANUAL_REPORT_ERROR_FORM';
export const RESET_MANUAL_REPORT_ERROR_FORM = 'RESET_MANUAL_REPORT_ERROR_FORM';
export const UPDATE_MANUAL_REPORT_ERRORS = 'UPDATE_MANUAL_REPORT_ERRORS';
export const SET_MANUAL_REPORT_FOCUSABLE_ERROR_ID =
  'SET_MANUAL_REPORT_FOCUSABLE_ERROR_ID';
export const UPDATE_MANUAL_REPORT_ERROR_STATUS =
  'UPDATE_MANUAL_REPORT_ERROR_STATUS';

export const SET_PROGRESS_TOGGLE = 'SET_PROGRESS_TOGGLE';
export const SET_PROGRESS_PERCENT = 'SET_PROGRESS_PERCENT';

export const LOAD_COMMENTS = 'LOAD_COMMENTS';
export const RESET_COMMENTS = 'RESET_COMMENTS';
export const TOGGLE_ISSUE_COMMENT_EDITOR = 'TOGGLE_ISSUE_COMMENT_EDITOR';
export const TOGGLE_ISSUE_STATUS_EDITOR = 'TOGGLE_ISSUE_STATUS_EDITOR';
export const UPDATE_COMMENT_READ = 'UPDATE_COMMENT_READ';

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';

export const LOAD_WCAG_SECTIONS = 'LOAD_WCAG_SECTIONS';
export const LOAD_WCAG_SECTION = 'LOAD_WCAG_SECTION';

export const ADD_AIASSISTANCE_QUESTION = 'ADD_AIASSISTANCE_QUESTION';
export const UPDATE_AIASSISTANCE_ANSWER = 'UPDATE_AIASSISTANCE_ANSWER';
export const RESET_AIASSISTANCE_QNA = 'RESET_AIASSISTANCE_QNA';
export const INITIATE_AIASSISTANCE = 'INITIATE_AIASSISTANCE';

export const LOAD_AI_CONFIG = 'LOAD_AI_CONFIG';
export const LOAD_AI_CONFIGS = 'LOAD_AI_CONFIGS';
export const CLEAR_AI_CONFIG = 'CLEAR_AI_CONFIG';
